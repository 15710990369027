@import '../bootstrap_overrides/_variables.scss';
@import '../mixins/_custom-mixins.scss';

//TWO COLUMN CONTENT
.pb-feature-gallery {
    .images-with-copy {
        .second-row {
            margin-top: -22em;
        }
        .third-row {
            margin-top: 45px;
        }

        @include bpr-media-breakpoint(down, md) {
            padding-left: 15px !important;
            padding-right: 15px !important;

            div {
                padding-left: 0 !important;
                padding-right: 0 !important;

                .pb-feature-gallery__textbox .container {
                    padding-left: 2rem !important;
                    padding-right: 2rem !important;
                }
            }
        }

        @include bpr-media-breakpoint(down, md) {
            display: flex;
            flex-wrap: wrap;

            .second-row {
                margin-top: 3rem;
                margin-left: -15px;
                width: 50%;
            }

            .third-row {
                margin-top: 3rem;
                margin-right: -15px;
                width: 50%;

                > .row {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
            }
        }
    }
    .three-images {
        @include bpr-media-breakpoint(down, md) {
            > .container-xl {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .third-row {
            margin-top: 30px;

            @include bpr-media-breakpoint(down, md) {
                div {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }

                .row {
                    > div {
                        padding-left: 0 !important;
                        padding-right: 0 !important;

                        .col-12 {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                        }
                    }
                }
            }
        }

        @include bpr-media-breakpoint(down, md) {
            .second-row {
                .row {
                    div {
                        padding-left: 0 !important;
                        padding-right: 0 !important;

                        &.col-6 {
                            padding-left: 15px !important;
                            padding-right: 15px !important;
                        }
                    }
                }
            }
        }
    }
    .rounded-textbox {
        padding-top: 5.5em !important;
        padding-left: 12%;
        padding-right: 12%;
        position: relative;
        z-index: 25;
    }
    .image-wrapper {
        width: 100%;
        position: relative;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .image-two {
        padding-top: 68%;
    }
    .image-three {
        padding-top: 59%;
    }

    @include bpr-media-breakpoint(down, md) {
        overflow: hidden;
        padding-left: 15px;
        padding-right: 15px;
    }
}